<template>
  <div v-if="tag != null">
    <page-header title="Просмотр тега" back>
      <tag-context-menu v-if="!tag.deleted" :tag="tag" :hook="reload" />
    </page-header>

    <q-card v-if="tag.deleted" class="q-pa-lg">
      Тег удалён.
    </q-card>
    <q-card v-else class="t-card">
      <div class="t-header">
        <div class="t-name">{{ tag.name }}</div>
      </div>

      <div v-if="tag.description" class="t-field">
        <div class="t-field-key">
          Описания тега
        </div>
        <div class="t-field-value tag-description">
          <div v-html="tag.description" />
        </div>
      </div>

      <div v-if="terms !== null" class="t-field">
        <div class="t-field-key">
          Связанные слова
        </div>
        <div class="t-field-value">
          <template v-if="terms.length === 0">
            С этим тегом пока нет связанных слов.
          </template>
          <template v-else>
            <div v-for="(letterTerms, letter) in termsByLetters" :key="letter" class="letter-terms">
              <div class="letter-terms--title">
                {{ letter }}
              </div>
              <div class="letter-terms--list">
                <div v-for="term in letterTerms" :key="term.id">
                  <router-link :to="{name: 'terms_view', params: {id: term.id}}">{{ term.name }}</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import loading from '../../loading.js'
import api from '../../api/api.js'
import PageHeader from '../../blocks/PageHeader.vue'
import TagContextMenu from '../../blocks/tags/TagContextMenu.vue'

export default {
  components: {
    PageHeader,
    TagContextMenu,
  },
  setup() {
    return {
      canEdit: ["editor", "admin"].includes(api.auth.getCurrentUser().role.id),
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()
    const tag = await api.tags.find(to.params.id)
    if (!tag) {
      next(false)
      return
    }
    const terms = await api.terms.findBy({tag, limit: 10000})
    next(vm => {
      vm.tag = tag
      vm.terms = terms
    })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      tag: null,
      terms: null
    }
  },
  methods: {
    async reload(tag) {
      this.tag = tag
    },
  },
  computed: {
    termsByLetters() {
      const termsByLetters = {}

      this.terms.forEach(term => {
        const letter = term.name.substr(0, 1).toUpperCase()
        if (!termsByLetters[letter]) {
          termsByLetters[letter] = []
        }
        termsByLetters[letter].push(term)
      })

      return termsByLetters
    },
  }
}
</script>

<style lang="scss">
.tag-description {
  white-space: pre;
}

.letter-terms {
  margin-bottom: 20px;

  &--title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: #2C7DDC;
  }
}
</style>
